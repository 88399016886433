








































































































































































































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default defineComponent({
  name: 'uOttawa1321Lab2Question1',
  components: {
    NumberValue,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        compB1: null,
        yield1: null,
        massAinML1: null,
        massBinML1: null,
        compB2: null,
        yield2: null,
        massAinML2: null,
        massBinML2: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      sampleCalculations: [] as File[],
    };
  },
  computed: {
    massAi(): number {
      return (this as any).taskState.getValueBySymbol('massAi').content;
    },
    massBi(): number {
      return (this as any).taskState.getValueBySymbol('massBi').content;
    },
    attachments(): File[] {
      return [...this.sampleCalculations];
    },
  },
});
